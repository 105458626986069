/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

/* header */
header {
  background: #151515;
  color: #ffffff;
}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* page container */
.container-fluid {
  margin: 0;
  padding: 0;
}

/* hero section */
.hero-container {
  height: 538px;
  margin: auto;
  display: flex;
  align-items: center;
  background: linear-gradient(to top right, rgb(162, 226, 243), #eab7d4);
}

@media screen and (max-width: 600px) {
  .hero-container {
    height: 100%;
    width: 100%;
    display: block;
    float: left;
    }
}

.hero-column {
  padding: 50px;
  width: 50%;
  float: left;
}

@media screen and (max-width: 600px) {
  .hero-column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.hero-column h1 {
  color: black;
  font-size: 40px;
  font-family: "Poppins";
  font-size: 50px;
}

.hero-column p {
  color: black;
  font-size: 20px;
  font-family: "Poppins";
}

/* sign up/waitlist form */
.form-control {
  width: 250px;
  display: block;
}

button {
  background-color: #A60261;
  border-radius: 6px;
  color: #FFFFFF;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 20px;
  border: none;
  transition: box-shadow .2s;
  margin-top: 3px;
  margin-bottom: 20px;
  width: 250px;
  display: block;
}

button:hover {
  box-shadow: #A60261 0 3px 8px;
}

/* benefits section */
.benefits-container {
  background: rgba(239,243,250,1);
  margin: 0;
  display: flex;
  height: 538px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.benefits {
  padding-top: 50px;
  text-align: center;
}

.benefits h1 {
  color: #A60261;
}

.benefit-list {
  float: left;
  width: 33.33%;
  padding: 0 10px;
  padding-top: 30px;
  color: #A60261;
}

.benefit-list h3 {
  color: black;
}

.benefit-list p {
  color: black;
}

@media screen and (max-width: 600px) {
  .benefit-list {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .benefits-container {
    height: 100%;
  }
}

/* process section */
.process-container {
  text-align: center;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .process-container {
    height: 100%;
  }
}

.process h1 {
  color: #A60261;
}

.process-column h3 {
  color: #A60261;
}

.process-column img {
  padding-left: 10px;
}

.process-column {
  float: left;
  width: 50%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 600px) {
  .process p {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .process h3 {
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .process-column {
    width: 100%;
    display: block;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .process-column img {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 600px) {
  .process-column p {
    padding-bottom: 10px;
  }
}

img {
  width: 100%;
}

/* final plug section */
.final-plug-container {
  background: rgba(239,243,250,1);
  text-align: center;
  height: 250px;
}

@media screen and (max-width: 600px) {
  .final-plug-container {
    height: 100%;
    display: block;
  }
}

/* final plug section */
.final-plug-container {
  display: flex;
  height: 350px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.final-plug-container button {
  margin: auto;
}

/* footer */
.footer-container {
  height: 50px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}